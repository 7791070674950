/**
 * This file only gets enqueued on countries single pages, news template (tpl.news.php), and background template (tpl.background.php)
 */
jQuery(function ($) {

    /**
     * Binds datepicker from jQuery UI to the filter_date input fields
     * Requires jQuery UI and jQuery UI Datepicker be enqueued properly to work.
     */
    $("#filter_date_from,#filter_date_to").datepicker({
        dateFormat: "yy-mm-dd"
    });

    /**
     * Binds Mimimalect to filter category and filter tags
     */
    $("select.cat").minimalect({placeholder: 'All Topics'});
    $("select.tag").minimalect({placeholder: 'All Tags'});
    $("select.show").minimalect({placeholder: 'All Shows'});
    $("select.series").minimalect({placeholder: 'All Series'});
    $("select.authors").minimalect({placeholder: 'All Authors'});
    $("select.types").minimalect({placeholder: 'All Content'});
    $("select.count").minimalect({placeholder: 'All Countries'});
    $("select.sort").minimalect({placeholder: 'Sort By'});
});